import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './App.css';
import './css/discord.css';

function App() {
  return (
    <div>
      <Navbar />
      <main>
        <section id="home" className="section">
          <h1>taigrweb</h1>
          <p>We specialize in innovative solutions for businesses ready to stand out.</p>
        </section>

        <section id="about" className="section">
          <h1>About Us</h1>
          <p>
            Currently, I am the sole member of this team, passionately working on innovative projects that aim to make a difference in the digital landscape.
          </p>
          <p>
            Below are some of the key projects I've undertaken:
          </p>
          <ul className="values-list">
            <li>
              <span role="img" aria-label="Robot">✨</span>
              <strong>TaigrBot</strong>: A Discord bot designed to enhance user interaction and provide instant support.
            </li>
            <li>
              <span role="img" aria-label="Globe">🌐</span>
              <strong>TaigrWeb</strong>: A responsive web application that delivers engaging user experiences.
            </li>
            <li>
              <span role="img" aria-label="Folder">🗂️</span>
              <strong>TaigrTab</strong>: A Minecraft tab plugin that aims to improve the player list's visual presentation.
            </li>
          </ul>
        </section>

        <section id="services" className="section">
          <h1>Our Services</h1>
          <div className="services">
            <div className="service-item">
              <h2>Web Development</h2>
              <p>Custom websites designed to drive engagement and conversions.</p>
            </div>
            <div className="service-item">
              <h2>Game Servers</h2>
              <p>We have a few game servers hosted for our community members.</p>
            </div>
          </div>
        </section>

        <section id="contact" className="section">
          <h1>- do not send yet since its not working yet -</h1>
          <form>
            <input type="text" placeholder="Your Name" required />
            <input type="email" placeholder="Your Email" required />
            <textarea placeholder="Your Message" required></textarea>
            <button type="submit">Send Message</button>
          </form>
        </section>

        <section id="discord-bot" className="section discord-bot-section">
          <div className="discord-bot-container">
            <div className="discord-bot-info">
              <h1 className="section-title">Invite taigrbot to Your Server</h1>
              <p className="section-description">
                Enhance your Discord server with taigrbot! Automate tasks, engage users, and improve community interaction with a variety of powerful features. Invite taigrbot to your server and experience its full potential today!
              </p>
              <div className="discord-bot-features">
                <ul>
                  <li><span className="feature-icon">✨</span> Automate tasks and save time.</li>
                  <li><span className="feature-icon">💬</span> Engage your community with fun interactions.</li>
                  <li><span className="feature-icon">⚙️</span> Moderation tools are coming soon.</li>
                  <li><span className="feature-icon">🔔</span> Many more features are begin developed.</li>
                </ul>
              </div>
              <a 
                href="https://discord.com/oauth2/authorize?client_id=922730341175791616&scope=bot&permissions=YOUR_PERMISSIONS"
                target="_blank"
                rel="noopener noreferrer"
                className="invite-button"
              >
                invite now
              </a>
            </div>
            <div className="discord-bot-image">
              <img src="/images/taigrbot.png" alt="botti" />
            </div>
          </div>
        </section>

      </main>
      <Footer />
    </div>
  );
}

export default App;

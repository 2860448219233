import React from 'react';
import './Footer.css'; // Import your footer CSS styles

const Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; {new Date().getFullYear()}<a href="https://www.instagram.com/realtiiker1" target="_blank" rel="noopener noreferrer">Tiiker1</a> All rights reserved.</p>
    </footer>
  );
};

export default Footer;

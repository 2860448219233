import React, { useState } from 'react';
import navbarLinks from './navbarLinks';
import './Navbar.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">tiiker1.dev</div>
        <button className="navbar-toggle" onClick={toggleMenu}>
          <span className="navbar-toggle-icon">&#9776;</span>
        </button>
        <ul className={`navbar-menu ${isOpen ? 'navbar-menu-open' : ''}`}>
          {navbarLinks.map((link, index) => (
            <li key={index} className="navbar-item">
              <a href={link.href} className={`navbar-link ${link.name === 'taigrbot' ? 'invite-link' : ''}`}>
                {link.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
